<!-- 
  Luigi's Restaurant 

  This single file component is displayed for invalid routes

-->

<!-- HTML Template -->
<template>
    <div align="center">
        <h1>404 Not Found</h1>
        <p>
          This page could not be found. It may have been moved or replaced. Please return 
          <a href="/">home</a>
        </p>
        
    </div>
</template>